
/* components */
import ViewHeader from "../../../components/ViewHeader/ViewHeader.vue";
import WarrantyDetailsViewer from "../../../components/WarrantyDetailsViewer/WarrantyDetailsViewer.vue";
import PartNumberAutoComplete from "./_components/PartNumberAutoComplete/PartNumberAutoComplete.vue";
import BuildTime from "./_components/BuildTime/BuildTime.vue";

/* services */
import {ShoppingCartService} from "@api/modules/shoppingCart/services/ShoppingCartService";
import {PartLookupService} from "@api/modules/partCatalog/PartLookupService";
import {UserManagementService} from "@api/modules/userManagement/services/UserManagementService";

/* utils */
import {StoreProxy} from "@api/common/StoreProxy";
import * as constants from '../../../constants';
import {CompanySettings} from "@api/modules/userManagement/CompanySettings";
import {Utils} from "@api/common/Utils";
import {StringUtils} from "@api/common/StringUtils";
import {Tracer} from "@api/common/Tracer";

/* objs */
import {OrderPartList} from "./models/OrderPartList";
import {OrderPartImageViewer} from "./models/OrderPartImageViewer";
import {OrderPartInventoryViewer} from "./models/OrderPartInventoryViewer";
import {OrderPartWarrantyViewer} from "./models/OrderPartWarrantyViewer";
import {UserPermissions} from "@api/core/UserPermissions";
import {CustomMessages} from "@api/core/CustomMessages";



import {AppConfig} from "@/config/AppConfig";



/**
 * OrderParts.vue UI logic
 */
export default {
    name: "OrderParts",
    components: {
      BuildTime,
      ViewHeader: ViewHeader,
      PartNumberAutoComplete: PartNumberAutoComplete,
      WarrantyDetailsViewer: WarrantyDetailsViewer
    },
    props: {
        isEmergencyOrder: {
            type: Boolean,
            default: false
        },
    },
    data(){
        return {
            userId: '',
            uploadUrl:'',
            uploadHeaders: {},
            list: new OrderPartList(StoreProxy.getAuth(this.$store), this.isEmergencyOrder),
            imageViewer: new OrderPartImageViewer(),
            inventoryViewer: new OrderPartInventoryViewer(),
            warrantyViewer: new OrderPartWarrantyViewer(),
            warrantySelected: '',
            partsLoading: false,
            availableCredit: -1,
            emergencyOrdersInStockMessage: AppConfig.current.getEmergencyOrderConfigs().inStockMessage,
            emergencyOrdersNewPartMessage: AppConfig.current.getEmergencyOrderConfigs().newPartMessage,
            emergencyOrdersOnlyBackOrderAllowedMessage: AppConfig.current.getEmergencyOrderConfigs().backOrderMessage,
            emergencyOrdersRestrictedPartMessage: AppConfig.current.getEmergencyOrderConfigs().restrictedEmergencyOrderPartMessage

        }
    },
    computed: {
        title() {

            const regularOrderPartTitle = UserPermissions.AllowDropShipOrdersOnly(this.$store) ? "Drop Ship" : "Order Parts";
            return (this.isEmergencyOrder) ? "Emergency Order" : regularOrderPartTitle;

        },
        key() {
            return (this.isEmergencyOrder) ? "2DOCZW" : "9MCKWR";
        },
        partList() {
            return this.list.rows;
        },
        outOfStockMessage() {
            return CompanySettings.getBackOrderMessage(this.$store);
        },
        closestBranchSelectedMessage() {
            return CompanySettings.getClosestBranchSelected(this.$store);
        },
        canSubmitOrder() {

            if (this._isViewOnly) {
                return false;
            }

            if (this.isEmergencyOrder) {
                for (let i = 0; i < this.list.rows.length; i++) {
                    if (this.list.rows[i].inStock) {
                        return false;
                    }
                }
            }

            let totalOrder = this._calculateTotalOrderPrice();

            if (totalOrder === 0) {
                return false;
            }

            for (let i = 0; i < this.list.rows.length; i++) {
                if (this.list.rows[i].isSuccess && this.list.rows[i].viewOnly === false) {
                    return true;
                }
            }

            return false;
        },
        isAer() {
            return Utils.isAER(this.$store);
        },
        isViewOnly() {
            return this._isViewOnly;
        },
        isAccountRestricted() {
            return UserPermissions.isOrdersRestricted(this.$store);
        },
        isOnlyDropShipAllowed() {
            return UserPermissions.AllowDropShipOrdersOnly(this.$store);
        },
        isActive() {

            if (!this._isViewOnly) {
                return true;
            }

            return StoreProxy.isActive(this.$store);
        },
        allowBackOrders() {
            return UserPermissions.canBackOrder(this.$store);
        },
        backOrderMessage() {
            return CustomMessages.backOrderRestrictedMessage(this.$store);
        },
        showAboveCreditLimitMessage() {

            if (!UserPermissions.isAccountOverCreditRestricted(this.$store)) {
                return false;
            }

            // if available credit is -1, it means we have not check for it yet.
            if (this.availableCredit === -1) {
                return false;
            }

            let totalOrder = this._calculateTotalOrderPrice();
            return totalOrder > this.availableCredit;

        },
        isEmergencyOrderEnabled(){
            return UserPermissions.isEmergencyOrderEnabled(this.$store);
        }

    },
    beforeRouteEnter (to, from, next) {
        next(vm => {

            if (typeof to.params.partNumber != 'undefined' && typeof to.params.add != 'undefined'){
                if (from.name === 'order-parts') {
                    vm.addPartNumber(to.params.partNumber);
                }
                else if  (from.name === 'emergency-order') {
                    vm.addPartNumber(to.params.partNumber);
                }
            }

        });

        next();
    },
    beforeRouteUpdate (to, from, next) {

        if (to.hash != null && to.hash.substring(0, 6) === '#reset') {
            this.list.clear();
        }

        if (typeof to.params.partNumber != 'undefined' && typeof to.params.add != 'undefined') {
            if (from.name === 'order-parts') {
                this.addPartNumber(to.params.partNumber);
            }
            else if  (from.name === 'emergency-order') {
                this.addPartNumber(to.params.partNumber);
            }
        }

        next();
    },
    beforeRouteLeave (to, from, next){
        next();
    },
    created(){

        this.auth = StoreProxy.getAuth(this.$store);
        this._isViewOnly = UserPermissions.isViewOnly(this.$store) || UserPermissions.isOrdersRestricted(this.$store);

        this.shoppingCartService = new ShoppingCartService(this.auth, this.isEmergencyOrder);
        this.lookupService = new PartLookupService(this.auth);
        this.userService = new UserManagementService(this.auth);

        this.userId = StoreProxy.getUserId(this.$store);
        this.uploadUrl =  `${process.env.VUE_APP_API_URL}/v1/shopping-carts/${this.userId}/bulk/file`;
        this.uploadHeaders = {
            'x-correlation-id': window.config.correlationId,
            'authorization': `Bearer ${this.$store.state.auth.jwt}`,
            'x-app-session': this.$store.state.auth.sessionId,
            'x-app-instance': window.appInstanceId
        };

        // make sure to flush any emergency order that had stayed on the shopping cart (memory) for any reason.
        if (this.isEmergencyOrder) {
            StoreProxy.ordersRemoveAll(this.$store, true);
        }
    },
    mounted(){

        this._getSavedItems();
        this._checkAvailableCreditForItems();
        this._internalLoadWarranties();
    },


    methods: {
        selectedPart(item, partNumber, index){

            if (partNumber == null || partNumber.length === 0){
                return;
            }

            this.clearedPart(item);
            let vm = this;
            try {

                item.load(partNumber, function (part) {

                    if (!vm.allowBackOrders && part.inStock === false) {
                        Tracer.current.debug("#9H1IOP:[BACK_ORDER_NOT_ALLOWED]: Back orders not allowed. Reset the quantity to 0");
                        item.quantity = 0;
                    } else {
                        if (!vm.allowBackOrders) {
                            vm.list.updateInventory(partNumber, index);
                        }
                    }

                });
            }
            catch(err){

                Tracer.current.error(err);
                this.$notify.error({
                    title: `Error while retrieving part ${item.partNumber} information`,
                });


            }

        },
        clearedPart(item){

           if (item == null)
           {
               return;
           }

           item.clear();

        },
        addLines(number){
            this.list.addLines(number);

        },
        addPartNumber(partNumber){

            let vm = this;
            this.list.addToNext(partNumber, this.allowBackOrders, function(line, part){

                if (!vm.allowBackOrders && part.inStock === false){
                    Tracer.current.debug("999120-(orders-parts): Back orders not allowed. Reset the quantity to 0");
                    line.quantity = 0;
                }

            });

        },
        reload(item){
          if (item != null){
              item.reload();
          }
        },
        clear(){

            // Emergency orders does not save on the server.
            if (this.isEmergencyOrder){
                this.list.clear();
                StoreProxy.ordersRemoveAll(this.$store, true);
                return;
            }

            let vm = this;
            this.$confirm(`Are you sure you want to permanently remove these items?`, 'Confirm Deletion', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(() => {

                try {

                    let userId = StoreProxy.getUserId(vm.$store);

                    vm.shoppingCartService.deleteItems(userId, false).then(() => {
                        vm.list.clear();
                        StoreProxy.ordersRemoveAll(vm.$store, false);
                    }).catch(err => {

                        Tracer.current.error('#3Q7ZEZ:[ERROR]:[DELETE_ERROR]: Failed to clear saved items from the server with an error');
                        Tracer.current.error(err);

                        vm.$notify.error({
                            title: 'Error while saving items on the server.',
                        });

                    });
                }
                catch(error){
                    Tracer.current.error('#3Q7ZEZ:[ERROR]:[DELETE_ERROR]: General Error');
                    Tracer.current.error(error);
                }

            }).catch(() => {
                //ignore.
            });

        },
        deleteItem(index){

            /*  Emergency orders does not save on the server
                and the rules is that only one item can be ordered.
            */
            if (this.isEmergencyOrder){
                this.list.clear();
                StoreProxy.ordersRemoveAll(this.$store, true);
                return;
            }

           this.list.delete(index, (itemId) => {

               try {

                   let userId = StoreProxy.getUserId(this.$store);
                   this.shoppingCartService.removeItem(userId, itemId).then(
                       result => {
                           if (result.success === true) {
                               Tracer.current.debug(`#573104:[ITEM_REMOVED][ITEM:${itemId}]: Removed from the server`);
                           } else {
                               Tracer.current.debug(`#d045214:[ITEM_NOT_FOUND][ITEM:${itemId}]: Item  *not found* on the server`);
                           }

                           // delete from the store.
                           StoreProxy.removeBulkOrderItem(this.$store, itemId, this.isEmergencyOrder);
                       }
                   ).catch(err => {
                       Tracer.current.error("#DO96MD:[ERROR_DELETE_ITEM]: Error when delete item from the server.");
                       Tracer.current.error(err);
                   });

               }
               catch(err){
                   Tracer.current.error(err);
                   Tracer.current.error("#GCVPTS:[ERROR_DELETE_ITEM]: error when delete item from the server.");
               }

           });

        },
        onSelectedBranch(command){
            let part = command.part;
            let branchId = command.branchId;

            Tracer.current.debug(`#WTO92:[BRANCH_SELECTED:${branchId}]`);


            if (this.allowBackOrders) {

                let informUser = this._informUserAboutAvailabilityInDifferentBranch(part, branchId);
                if (informUser) {
                    this._notifyUserAboutAvailabilityInDifferentBranch().then(resolve => {

                        part.setSelectedBranchId(branchId);

                        window.tracer.debug(`[d359732] Branch changed!. Branch Id chosen: ${branchId}`)
                    }).catch(rej => {
                        // ignore.
                    });
                } else {

                    part.setSelectedBranchId(branchId);
                    window.tracer.debug(`[d359732] Branch changed!. Branch Id chosen: ${branchId}`)
                }
            }
            else{

                part.setSelectedBranchId(branchId);

                if (part.branchInventory === 0){
                    Tracer.current.debug("[dbg-147933] Selected branch does not have inventory");
                    part.quantity = 0;
                }
                else{

                    if (part.branchInventory < part.quantity || part.quantity === 0) {
                        part.quantity = 1;
                    }

                }

            }

        },
        showImages(item){
          this.imageViewer.showImages(item);
        },
        showInventory(item){
          this.inventoryViewer.showInventory(item);
        },
        onWarrantySelected(item){

            Tracer.current.debug(`#G6FT1R:[WARRANTY_SELECTED][ID:${item.id}]`);
            if (item.id !== '') {
                StoreProxy.setBulkOrderWarranty(this.$store, item);
            }
            else{
                StoreProxy.setBulkOrderWarranty(this.$store, '');
            }

            this.inventoryViewer.close();
            this._internalPostOrder();

        },

        submit() {

            if (this._isViewOnly){
                window.tracer.warn("#DPL5LH:[VIEW_ONLY]: Can't submit order.");
                return;
            }

            let vm = this;
            let notInStockMessage = this._showNotInStockMessage();

            if (notInStockMessage){

                let companySettings = CompanySettings.getCompanyInstance(this.$store);
                let msg = companySettings.getSetting(constants.SETTINGS_BACKORDER_CONFIRMATION_MESSAGE_KEY );

                this.$confirm(msg, "NOT IN STOCK", {
                    confirmButtonText: "Yes, Continue",
                    cancelButtonText: "Cancel",
                    type: "warning",
                    customClass: "not-in-stock-warning-message",
                    dangerouslyUseHTMLString: true
                }).then(() => {
                    vm._internalSubmit();
                }).catch(() => {
                    // do nothing/
                });
            }
            else{
                this._internalSubmit();
            }
        },
        onUploadSuccess(response, file, fileList) {

            try {

                this.partsLoading = true;
                StoreProxy.setOrderItems(this.$store, response.items, response.cartId);

                let parts = StoreProxy.getBulkOrderItems(this.$store);

                this.list.addLoadedParts(parts);

                this.partsLoading = false;


            }
            catch(err){
                this.partsLoading = false;
                Tracer.current.error('#EK2GWK onUploadSuccess event error');
                Tracer.current.error(err);
            }

        },
        isEmptyOrNull(str){
           return StringUtils.isEmptyOrNull(str);
        },
        _internalSubmit(){
            if (this.warrantyViewer.hasWarranties){
                this.warrantyViewer.open();
            }
            else{
                this._internalPostOrder();
            }
        },
        _internalPostOrder(){

            try {
                let orderItems = [];

                this.list.rows.forEach(function (partOrder) {

                    if (partOrder.partNumber != null && partOrder.isSuccess) {

                        let tmpSelectedBranchId = "";
                        const tmpSelectedBranch = partOrder.getSelectedBranch();

                        if (tmpSelectedBranch != null) {
                            tmpSelectedBranchId = tmpSelectedBranch.id;
                        }

                        orderItems.push({
                            qty: partOrder.quantity,
                            partNumber: partOrder.partNumber.toUpperCase(),
                            branchId: tmpSelectedBranchId
                        });
                    }
                });


                this.shoppingCartService.addItems(StoreProxy.getUserId(this.$store), orderItems, this.isEmergencyOrder).then(data => {

                    StoreProxy.setOrderItems(this.$store, data.items, data.cartId, this.isEmergencyOrder);

                    const orderType = (this.isEmergencyOrder) ? 'emergency' : 'bulk'
                    this.$router.push({name: "place-order", params: {type: orderType}});

                }).catch(err => {
                    
                    Tracer.current.criticalError(err, {
                        message: "CYZIF7-(order parts): Error when submitting parts to the server",
                        isEmergencyOrder: this.isEmergencyOrder
                    });

                    this.$notify.error({
                        title: 'Error! - Failed to submit parts to the server',
                    });
                });

            }
            catch(err2){

                Tracer.current.criticalError(err2, {
                    message: "3IDX4Q-(order parts): Error submitting order!"
                });
            }
        },
        _internalLoadWarranties(){

            if (this._isViewOnly){
                Tracer.current.info("#2ATZQK:[SKIP_WARRANTY]: Skip loading warranties. It is view only.")
                return;
            }

            let lookupService = new PartLookupService(StoreProxy.getAuth(this.$store));

            let vm = this;
            lookupService.getWarranties().then(
                warranties => {

                    if (warranties.length > 0) {
                        window.tracer.debug('[d717726]: warranties options loaded!');
                        vm.warrantyViewer.setWarrantyList(warranties);
                    }
                    else{
                        window.tracer.debug('[d236676]: no warranties');
                    }
                }
            ).catch(e => {
                window.tracer.error('[e576587] Error loading warranty list from api.');
                if (!e) {
                    window.tracer.error(e);
                }
            });
        },
        _getSavedItems(){

            if (this._isViewOnly){
                window.tracer.info("#FPSL4G:[SKIP_LOADING_SAVED_ITEMS]: Skip checking the user's shopping cart. It is view only.")
                return;
            }

            let savedItems = StoreProxy.getBulkOrderItems(this.$store);
            if (savedItems == null || savedItems.length === 0){

                let userId = StoreProxy.getUserId(this.$store);

                this.partsLoading = true;

                this.shoppingCartService.getItems(userId, false).then(items => {
                    if (items != null && items.length > 0) {
                        this._setSavedItems(items);
                    }

                    this.partsLoading = false;

                }).catch(err => {

                    this.partsLoading = false;
                    Tracer.current.error(err);
                    this.$notify.error({
                        title: 'Fail to retrieve saved items.',
                    });

                });
            }
            else {

                this.partsLoading = true;

                try {

                    this._setSavedItems(savedItems);
                    this.partsLoading = false;

                } catch (err2) {

                    Tracer.current.error(err2);
                    this.partsLoading = false;

                }
            }
        },
        _setSavedItems(parts){

            this.list.addLoadedParts(parts);
            if (this.list.rows.length < 5) {
                this.list.addLines(5 - this.list.rows.length);
            }
            else{
                this.list.addLines(5);
            }
        },
        _addDefaultLines(){
            if (this.isEmergencyOrder){
                return;
                // do not add more lines for emergency order.
            }
            this.list.addLines();
        },
        _informUserAboutAvailabilityInDifferentBranch(part, branchId) {

            if (part == null) {
                throw new Error('#856UB2:!!NULL_ARG!!: part is not an object');
            }

            if (branchId == null) {
                throw new Error('#B67344:!!NULL_ARG!!: branchId is not defined.');
            }

            let selectedBranch = part.getBranch(branchId);
            if (selectedBranch == null) {

                Tracer.current.error('#DCD3F8:[NO_SELECTED_BRANCH_FOUND]');
                return false;
            }

            let stockInDifferentBranch = false;

            if (selectedBranch.qty > 0) {
                return false;
            }

            part.branches.forEach((branch) => {
                if (branch.qty > 0 && selectedBranch.id !== branch.id) {
                    stockInDifferentBranch = true;
                }
            });

            return stockInDifferentBranch;

        },
        isNewPart(item){
          let group = item.group;
          return AppConfig.current.getEmergencyOrderConfigs().isNewPart(group);
        },
        /**
         * Whether the part can be order as an emergency order or not.
         * @param item
         * @returns {boolean}
         */
        isEmergencyPartRestricted(item){
            let group = item.group;
            return AppConfig.current.getEmergencyOrderConfigs().isRestricted(group);
        },
        isEmergencyOrderPermitted(item){
            return item.qtyInStockInEmergencyOnlyBranch === 0;
        },

        _notifyUserAboutAvailabilityInDifferentBranch() {

            let vm = this;
            return new Promise((resolve, reject) => {

                const h = vm.$createElement;
                this.$msgbox({
                    title: 'Warning',
                    message: h('div', {class: 'warning-branch-confirmation-dialog'}, [
                        h('div', null, 'This part is out of stock at the location selected. It is in stock at a different location.'),
                        h('div', {style: 'font-weight: bold; margin-top: 5px;'}, 'Do you want to continue?')
                    ]),
                    confirmButtonText: 'Yes, Continue',
                    cancelButtonText: 'No, Cancel',
                    showCancelButton: true,
                    type: 'warning',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            resolve();
                            done();
                        } else {
                            reject();
                            done();
                        }
                    }
                });
            });
        },
        _showNotInStockMessage(){

            if (this.isEmergencyOrder){
                return false;
            }

            let show = false;
            for(let i = 0; i < this.list.rows.length; i++){

                let tmpPart = this.list.rows[i];
                if (tmpPart.isSuccess) {
                    let tmpBranch = tmpPart.getSelectedBranch();

                    if (tmpBranch.qty === 0) {
                        show = true;
                        break;
                    }
                }
            }

            return show;
        },
        _calculateTotalOrderPrice() {

            let total = 0;

            for (let i = 0; i < this.list.rows.length; i++) {
                total = total + this.list.rows[i].totalPrice;
            }

            return total;
        },
        _checkAvailableCreditForItems(){

             let check = UserPermissions.isAccountOverCreditRestricted(this.$store);

             if (!check) {
                 return;
             }

             let vm = this;
             this.userService.getAvailableCredit().then(value => {
                 vm.availableCredit = value;
             });

        }


    }
}