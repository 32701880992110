import { detect } from 'detect-browser'
import {StoreProxy} from "@api/common/StoreProxy";
import {UserPermissions} from "@api/core/UserPermissions";
const browser = detect();

export default {
    name: 'Nav',
    props: ['mode', 'isCollapse'],
    data() {
        return {
            isIe: true,
            isEdge: true,
            activeLink: null
        }
    },
    computed:{
        hidePartsCatalog(){

            return (StoreProxy.getCompanyId(this.$store) === window.config.aerCompanyId);
        },
        isActive(){
            return StoreProxy.isActive(this.$store);
        },
        isEmergencyOrderEnabled(){
            return UserPermissions.isEmergencyOrderEnabled(this.$store);
        },
        orderPartsTitle() {
            if (UserPermissions.AllowDropShipOrdersOnly(this.$store)){
                return "Drop Ship";
            }
            else {
                return "Order Parts";
            }
        }
    },
    methods: {
        goto(index, indexPath) {
            if(index.charAt(0) === '/') {
                this.$router.push({ path: index, params: { partNumber: ''} });

                this.$emit('push-page', {page:index})
            }

        },
        setLink(path) {
            this.activeLink = path
        }
    },
    created() {

        if(browser.name !== 'ie') this.isIe = false;
        if(browser.name !== 'edge') this.isEdge = false;

        this.setLink(this.$router.currentRoute.path);
        this.$router.afterEach((to, from) => {
            this.setLink(this.$router.currentRoute.path)
        })

    },
    mounted() {
    }
}