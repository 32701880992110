import {Tracer} from "@api/common/Tracer";
import {StringUtils} from "@/lib/StringUtils";

export class AddressUtils {

    static readonly abbreviations: { [key: string]: string } = {
        street: "St",
        "st.": "St",
        st: "St",
        avenue: "Ave",
        "ave.": "Ave",
        ave: "Ave",
        road: "Rd",
        "rd.": "Rd",
        rd: "Rd",
        boulevard: "Blvd",
        "blvd.": "Blvd",
        blvd: "Blvd",
        lane: "Ln",
        "ln.": "Ln",
        ln: "Ln",
        drive: "Dr",
        "dr.": "Dr",
        dr: "Dr",
        court: "Ct",
        "ct.": "Ct",
        ct: "Ct",
        apartment: "Apt",
        "apt.": "Apt",
        apt: "Apt",
        suite: "Ste",
        "ste.": "Ste",
        ste: "Ste"
    };



    static isAddressMatch(address1 : string, address2: string) : boolean {

        const normalizedAddress1 : string = this.normalizeAddress(address1);
        const normalizedAddress2 : string = this.normalizeAddress(address2);

        Tracer.current.debug(`CWS9AK20-(address_utils): Normalized address match. Address1: ${normalizedAddress1} - Address2: ${normalizedAddress2} `)
        return (normalizedAddress1 === normalizedAddress2);
    }

    static normalizeCSZ(city: string, state: string, zipcode: string) : string{

        if (city == null){
            city = "";
        }

        if (state == null){
            state = "";
        }


        if (zipcode == null){
            zipcode = "00000";
        }

        const cityNormalized = AddressUtils.removeSpecialCharacters(city.toUpperCase());
        const stateNormalized = AddressUtils.removeSpecialCharacters(state.toUpperCase());
        const zipNormalized = AddressUtils.removeSpecialCharacters(zipcode.toUpperCase());

        return cityNormalized+stateNormalized+zipNormalized;
    }

    /**
     * Normalize Address
     * @param address
     */
    private static normalizeAddress(address: string): string {
        // Convert abbreviations to a standard format

        if (StringUtils.isEmptySpacesOrNull(address)){
            return "";
        }

        // Replace abbreviations using regex
        let normalized = address.replace(/\b(street|st\.?|avenue|ave\.?|road|rd\.?|boulevard|blvd\.?|lane|ln\.?|drive|dr\.?|court|ct\.?|apartment|apt\.?|suite|ste\.?)\b/gi, match => {
            return AddressUtils.abbreviations[match.toLowerCase()] || match;
        });

        normalized = AddressUtils.removeSpecialCharacters(normalized).toUpperCase();
        Tracer.current.debug(`8MO6CM-(street_comparison)[normalize]: Address: ${address}, Normalized: ${normalized}`);

        return normalized;
    }


    private static removeSpecialCharacters(input: string): string {
        return input.replace(/[.\-\s]/g, '');
    }
}